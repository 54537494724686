import React, { useState } from "react";
import emailjs from "emailjs-com";
import vrStandupImage from "./assets/vr_standup_comedy.jpeg";
import "./App.css";

function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      "service_9oai9ru",  // Replace with your EmailJS Service ID
      "template_wvpzrwm", // Replace with your EmailJS Template ID
      formData,
      "c5ktBmM9PjQ53eDAg" // Replace with your EmailJS User ID
    )
    .then((response) => {
      alert("Message sent successfully!");
      setFormData({ name: "", email: "", message: "" });
    })
    .catch((error) => {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    });
  };

  return (
    <div className="App">
      {/* Landing Section */}
      <header className="landing">
        <h1>Welcome to StandupVR.com</h1>
        <p>Your virtual stage to practice and perform stand-up comedy.</p>
         <img src={vrStandupImage} alt="StandupVR Comedy" className="center-image" />
      </header>

      {/* Contact Section */}
      <section className="contact">
        <h2>Contact Us</h2>
        <p>Email: <a href="mailto:contact@standupvr.com">contact@standupvr.com</a></p>
        <p>Join our Discord: <a href="https://discord.com/invite/your-link" target="_blank" rel="noopener noreferrer">Click Here</a></p>
        
        {/* Contact Form */}
        <form onSubmit={handleSubmit} className="contact-form">
          <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
          <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
          <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </section>
    </div>
  );
}

export default App;
